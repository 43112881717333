import React, {useMemo} from 'react';

import {Rating} from './Rating';
import stringCapitalize from 'shared/utils/stringCapitalize';

import {IFormFields} from 'shared/services/api/FormService';

interface IProps {
  field: IFormFields;
}

export const RatingForm: React.FC<IProps> = ({field}) => {
  const [start, end] = (field.opcoes[0]?.nome || '0,0').split(',').map(Number);

  const options = useMemo(
    () =>
      start && end
        ? Array.from({length: end - start + 1}, (_, i) => start + i)
        : [],
    [end, start],
  );

  if (!field.opcoes[0]) return null;

  return (
    <Rating
      name={stringCapitalize(field.nomeCampo, field.idCampo)}
      label={field.obrigatorio ? `${field.nomeCampo} *` : field.nomeCampo}
      options={options}
    />
  );
};
