import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Grid,
  Card,
  Button,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

import {useField} from '@unform/core';

interface IProps {
  name: string;
  label: string;
  value?: number;
  options: number[];
}

export const Rating: React.FC<IProps> = ({name, label, options}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {fieldName, defaultValue, registerField, error} = useField(name);
  const [selectValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => selectValue,
      setValue: (_, value) => setSelectedValue(value),
    });
  }, [fieldName, selectValue, registerField]);

  const handleChange = (value: number): void => {
    setSelectedValue(value);
  };

  return (
    <Card variant="outlined" style={{backgroundColor: 'inherit'}}>
      <Box px={1} py={2}>
        <FormControl component="fieldset" error={!!error}>
          <Grid container spacing={1} ref={inputRef}>
            <Grid item xs={12}>
              <FormLabel component="legend">{label}</FormLabel>
            </Grid>
            {options.map((option) => (
              <Grid key={option} item>
                <Button
                  disableElevation
                  color="primary"
                  variant={option === selectValue ? 'contained' : 'outlined'}
                  onClick={() =>
                    handleChange(selectValue === option ? -1 : option)
                  }>
                  {option}
                </Button>
              </Grid>
            ))}
            {error && (
              <Grid item xs={12}>
                <FormHelperText error={!!error}>{error}</FormHelperText>
              </Grid>
            )}
          </Grid>
        </FormControl>
      </Box>
    </Card>
  );
};
